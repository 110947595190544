/**
 *
 * Script: Flickity Sliders Init
 *
 * @since 1.0.0
 * @package Leisy
 */

(function ($) {
	$(function () {

		//Set sliders' wrapper containers as vars for reuse
		var $blogSlider = $('.recent-blogs-slider');
		var $portfolioSlider = $('.portfolio-slider-wrapper > .wrap');

		//sanity check
		if (!('flickity' in $.fn)) {
			return;
		}

		//Initialize Blog Slider and Set Options
		$blogSlider.flickity({
			wrapAround: true,
			freeScroll: true,
			pageDots: false,
			cellAlign: 'left',
		});

		//Initialize Portfolio Slider and Set Options
		$portfolioSlider.flickity({
			wrapAround: true,
			freeScroll: true,
			pageDots: false,
			cellAlign: 'left',
		});

		//Set custom prev/next button arrow icons
		$('.flickity-prev-next-button.previous', $blogSlider).html('<i class="fal fa-long-arrow-left"></i>');
		$('.flickity-prev-next-button.next', $blogSlider).html('<i class="fal fa-long-arrow-right"></i>');
		$('.flickity-prev-next-button.previous', $portfolioSlider).html('<i class="fal fa-long-arrow-left"></i>');
		$('.flickity-prev-next-button.next', $portfolioSlider).html('<i class="fal fa-long-arrow-right"></i>');

	});
})(jQuery);
